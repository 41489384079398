@import url(https://fonts.googleapis.com/css?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.p-button-primary {
  background-color: #01abb5 !important;
    color:white !important;
    border: #01abb5 thin solid !important;
    border-radius: 5px !important;
}

.p-button-secondary {
  border: white solid thin !important;
  color: white !important;
}

.p-button-danger {
  border: none !important;
  background-color: transparent !important;
  color: #DB4436 !important;
  text-transform: uppercase !important;
  font-weight: bold;
}

.p-button-warning {
  border: none !important;
  background-color: transparent !important;
  color: darkorange !important;
  text-transform: uppercase !important;
  font-weight: bold;
}

.p-button-info {
  border: none !important;
  background-color: transparent !important;
  color: #4c3494 !important;
  text-transform: uppercase !important;
  font-weight: bold;


}

.p-button-success {
  width: 90%;
  height: 50px;
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 30px;
  background-color: #4c3494 !important;
    border-color: #4c3494 !important;
  /* background-color: white !important; */
  
}

a:hover {
  text-decoration: none !important;
}

input {
  width: 80%;
  height: 50px;
  border-radius: 5px;
}

input:focus,
select:focus,
textarea:focus,
button:focus,
.p-button:focus,
.p-dialog-titlebar-icon:focus,
.p-dialog-titlebar-close-icon:focus
 {
    outline: none !important;
    box-shadow: none !important;
}

.p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon {
  color: #6D7173 !important;
}

.p-inputtext {
  width: 90% !important;
}

.p-inputtext:enabled:hover {
  border-color: #dddddd !important;
}

.p-inputtext:enabled:focus {
  border-color: #dddddd !important;
  outline: none;
  box-shadow: none !important;
}

.p-dialog .p-dialog-content{
  border: none !important;
}

.p-dialog .p-dialog-footer{
  border: none !important;
}

.p-dialog .p-dialog-titlebar {
  /* background-color: #01abb5 !important;
  border: 1px solid #01abb5 !important; */
  color: #6D7173 !important;
  background-color: white !important;
  border: none !important;
}

.react-confirm-alert-body {
  border-radius: 0px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif !important;
  font-weight: 100;
}

.react-confirm-alert-body h1 {
  font-size: 1.5em !important;
}

.react-confirm-alert-button-group button {
  border-radius: 0px !important;
  background: none !important;
  color: #5DC2A7 !important;
  text-transform: uppercase !important;
}

.green {
  background-color: #5DC2A7 !important;
  color: white;
  border: none;
}

svg {
  overflow: visible !important;
}
.App {
  text-align: center;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media (min-width: 576px) {
  .App {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
  }
}

.TitleBar_old {
    min-height: 60px;
    background-color: #01abb5;
    margin-bottom: 25px;
    color: white;
    text-transform: capitalize;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    
    
}

.TitleBar_old .p-button {
    right: 10px;
    position: absolute;
    

}

.TitleBar {
    font-weight: bold;
    color: #000 !important;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: env(safe-area-inset-top) 10px 10px 10px;
   height: 60px;
   display: flex;
   -webkit-justify-content: center;
           justify-content: center;
    background-color: #F9F9F9;
    position: fixed;
    width: 100%;
    z-index: 99;
    top: 0;
}

.TitleBar .menu {
    border: none !important;
    background-color: transparent !important;
    right: 0;
    position: fixed;
    
}

.TitleBar .p-menu .p-submenu-header {

    background-color: #6D7173 !important;
    
}

.TitleBar .p-menuitem {
    text-align: left;
}

@media (min-width: 576px) {
    .TitleBar {
        /* width: 500px;
        position: unset; */
        left: 0;
    }
}
/* 
.Register {
    
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    
}

.Register form {
    height: 60%;
}

.Register input {
    width: 90%;
    height: 50px;
    border: none;
    border-bottom: 1px solid #dddddd;
    border-radius: 0;
}

.Register input[type=password] {
    font-family: inherit !important;
}

.Register .p-inputtext:enabled:focus {
    border-bottom-color: #5DC2A7 !important;
}

.Register .p-button {
    width: 90%;
    height: 50px;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 30px;
    
}

.Register .logo-wrapper {

    width: 100%;
    height: 40%;
    
}

.Register .logo-wrapper .animain {
    position: relative;
}
.Register .logo-wrapper .animain .screen {
    position: relative;
    height: 100%;
} */

.Register {
    
    /* margin-top: 80px; */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    
}

.Register form {
    /* height: 50%; */
}

.Register input {
    width: 90%;
    height: 50px;
    border: none;
    border-bottom: 1px solid #dddddd;
    border-radius: 0;
}

.Register input[type=password] {
    font-family: inherit !important;
}

.Register .p-inputtext:enabled:focus {
    border-bottom-color: #4c3494 !important;
}

.Register .p-button {
    width: 90%;
    height: 50px;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 30px;
    background-color: #4c3494;
    
}

.Register .logo-wrapper {

    width: 100%;
    height: 50%;
    /* background-color: red; */
    
}

.Register .form-wrapper {
    height: 50%;
    z-index: 999;
    overflow: scroll;
}

.Register .acceptTermsWrapper {
    /* width: 100%; */
    /* display: flex; */
    /* flex-direction: row-reverse; */
    -webkit-align-items: center;
            align-items: center;
    /* justify-content: flex-start; */
    padding: 0 25px;
    color: #666666;
}

.Register .acceptTermsWrapper #acceptTerms {
    margin: 0 10px;
}

@media (min-width: 768px) {
    .Register {
    
        /* margin-top: 80px; */
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        /* position: fixed; */
        width: 100%;
        height: 100%;
        -webkit-align-items: center;
                align-items: center;
        
    }

    .Register .logo-wrapper {

        width: 50%;
        height: 50%;


        
    }

    .Register .form-wrapper {
        /* height: 50%;
        z-index: 999;
        overflow: scroll; */
        width: 50%;
        height: 100%;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        
    }

    .Register form {
        /* height: 50%; */
        width: 100%;
    }
}


.AnimatedLogo {
  /* background-color: red; */
  height: 50vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
}

.AnimatedLogo #head {
  -webkit-animation: moveHead 0.85s ease-in-out infinite alternate;
  animation: moveHead 0.85s ease-in-out infinite alternate;
}

.AnimatedLogo #hands {
  -webkit-transform-origin: 10% 35%;
          transform-origin: 10% 35%;
  -webkit-animation: rotateHands 0.85s ease-in-out infinite alternate;
  animation: rotateHands 0.85s ease-in-out infinite alternate;
}

.AnimatedLogo #shin {
  -webkit-transform-origin: 15% 60%;
          transform-origin: 15% 60%;
  webkit-animation: rotateShin 0.85s ease-in-out infinite alternate;
  -webkit-animation: rotateShin 0.85s ease-in-out infinite alternate;
          animation: rotateShin 0.85s ease-in-out infinite alternate;
}

.AnimatedLogo #body {
  -webkit-transform-origin: 10% 50%;
          transform-origin: 10% 50%;
  webkit-animation: rotateBody 0.85s ease-in-out infinite alternate;
  -webkit-animation: rotateBody 0.85s ease-in-out infinite alternate;
          animation: rotateBody 0.85s ease-in-out infinite alternate;
}

.AnimatedLogo #person {
  /* position: absolute; */
  top: 37%;
  left: 60%;
  width: 60%;
  -webkit-animation: movePerson 0.85s ease-in-out infinite alternate;
  animation: movePerson 0.85s ease-in-out infinite alternate;

}

.AnimatedLogo #shadow {
  /* position: absolute; */
  top: 52%;
  left: 79%;
  -webkit-animation: pulse 0.85s ease infinite alternate;
  animation: pulse 0.85s ease infinite alternate;
}

.AnimatedLogo #_1 {
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  animation-iteration-count: initial;
  -webkit-animation-iteration-count: initial;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: linear;
}
.AnimatedLogo #_2 {
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  animation-iteration-count: initial;
  -webkit-animation-iteration-count: initial;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.AnimatedLogo #_3 {
  -webkit-animation-fill-mode: backwards;
          animation-fill-mode: backwards;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  animation-iteration-count: initial;
  -webkit-animation-iteration-count: initial;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: linear;

  -webkit-animation-delay: 1s;

          animation-delay: 1s;
}

.AnimatedLogo svg {
  overflow: visible;
  margin: 0;
  position: absolute;
  top: 45%;
  left: 60%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 75%;
}

@-webkit-keyframes pulse {
  0% {
    width: 6.5em;
  }
  100% {
    width: 6em;
  }
}
@keyframes pulse {
  0% {
    width: 6.5em;
  }
  100% {
    width: 6em;
  }
}

@-webkit-keyframes moveHead {
  0% {
    -webkit-transform: translate(-110px, -180px);
    -moz-transform: translate(-110px, -180px);
    -ms-transform: translate(-110px, -180px);
  }

  100% {
    -webkit-transform: translate(-200px, -210px);
    -moz-transform: translate(-200px, -210px);
    -ms-transform: translate(-200px, -210px);
  }
}

@-webkit-keyframes movePerson {
  0% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(0, -30px);
    -moz-transform: translate(0, -30px);
    -ms-transform: translate(0, -30px);
  }
}

@-webkit-keyframes rotateHands {
  0% {
    -webkit-transform: rotate(0deg) translate(-120px, -190px);
    transform: rotate(0deg) translate(-120px, -190px);
  }
  100% {
    -webkit-transform: rotate(-190deg) translate(-170px, -110px);
    transform: rotate(-190deg) translate(-170px, -110px);
  }
}
@keyframes rotateHands {
  0% {
    -webkit-transform: rotate(0deg) translate(-120px, -190px);
    transform: rotate(0deg) translate(-120px, -190px);
  }
  100% {
    -webkit-transform: rotate(-190deg) translate(-170px, -110px);
    transform: rotate(-190deg) translate(-170px, -110px);
  }
}

@-webkit-keyframes rotateShin {
  0% {
    -webkit-transform: rotate(-30deg) translate(-130px, -190px);
    transform: rotate(-30deg) translate(-130px, -190px);
  }
  100% {
    -webkit-transform: rotate(0deg) translate(-125px, -190px);
    transform: rotate(0deg) translate(-125px, -190px);
  }
}
@keyframes rotateShin {
  0% {
    -webkit-transform: rotate(-30deg) translate(-130px, -190px);
    transform: rotate(-30deg) translate(-130px, -190px);
  }
  100% {
    -webkit-transform: rotate(0deg) translate(-125px, -190px);
    transform: rotate(0deg) translate(-125px, -190px);
  }
}

@-webkit-keyframes rotateBody {
  0% {
    -webkit-transform: rotate(0deg) translate(-120px, -200px);
    transform: rotate(0deg) translate(-120px, -200px);
  }
  100% {
    -webkit-transform: rotate(-30deg) translate(-110px, -200px);
    transform: rotate(-30deg) translate(-110px, -200px);
  }
}
@keyframes rotateBody {
  0% {
    -webkit-transform: rotate(0deg) translate(-120px, -200px);
    transform: rotate(0deg) translate(-120px, -200px);
  }
  100% {
    -webkit-transform: rotate(-30deg) translate(-110px, -200px);
    transform: rotate(-30deg) translate(-110px, -200px);
  }
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.RegisterRedirect {
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.RegisterRedirect button {
    width: 90%;
    margin-top: 10px;
    height: 50px;
}
.CategoryHeader {
    color: white;
    text-transform: uppercase;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    padding: 0px 18px;
    height: 60px;
    font-weight: bold;
    /* justify-content: space-between; */
    
}

.CategoryHeader .catImage {
    max-height: 40px;
    /* clip-path: circle(37.8% at 50% 50%); */
    z-index: 0;
    
}
li.Activity {
    display: -webkit-flex;
    display: flex;
    text-align: left;
    font-size: 0.8em;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px 10px 10px 10px;
    border-top: 1px solid #F4F5F7;
    min-height: 80px;
    
}

.Activity button.status {
    background-color: white;
    border: 3px solid #97D8C7;
    color: #97D8C7;
    border-radius: 5px;
    height: 23px;
    width: 23px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 0.8em;
    margin-top: 20px;

}

.Activity button.promptLink {
    font-weight: bold;
    padding: 0;
    border: none !important;
    background-color: transparent !important;
    text-align: left;
    text-decoration: underline;
}

.Activity .LearnMore, .Activity .url {
    font-weight: 500;
    text-decoration: underline;
    color: #878787;
}

.Activity .text {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-size: 1.2em;
    font-weight: bold;

}

.Activity .text .action {
    
    font-weight: bold;
    font-style: italic;

}

.Activity .text .action i {
    font-size: 1.2em;
    
}

.Activity .text .action a {
    font-size: 1.2em;
    text-decoration: none;
    
    
}

.Activity .points {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    min-width: 50px;
    font-size: 2.0em;
}

.Activity .checkbox {
    font-size: 48px;
    color: #000;
}
.Activity .challengeCompleteText {
    color: rgb(255, 220, 155);
    font-weight: bold;
    padding: 0;
    border: none !important;
    background-color: transparent !important;
    text-align: left;
}

.Activity.challenge {
    background-color: white;
}

.Activity.complete {
    background-color: white;
    
}

.Activity .activityPoints {
    padding: 5px;
    font-weight: bold;
    font-style: italic;
}

.Activity .points {
    cursor: pointer;
}

.Activity .help {
    /* color: #dfdfdf; */
    cursor: pointer;
    padding-left: 10px;
}

.Activity .url {
    /* color: #dfdfdf; */
    /* cursor: pointer; */
    /* color: black; */
    /* padding-left: 10px; */
}

.Activity.daily {
    /* background-color: lightseagreen; */
}
.ActivityHistory.hide {
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
    left: 0;
}

.ActivityHistory.show {
    -webkit-transform: translateY(none);
            transform: translateY(none);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
    left: 0;
}

.ActivityHistory {
    height: 100vh;
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    overflow: scroll;
    z-index: 999;

}

.ActivityHistory .day {
    font-weight: bold;
    padding: 0 5px;
    margin: 0 6px;
    width: 60px;
}

.ActivityHistory .title {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-justify-content: right;
            justify-content: right;
    -webkit-align-items: right;
            align-items: right;
    position: fixed;
    
}

.ActivityHistory .ActivityTitle {
    position: relative;
    margin: 40px 10px 40px 10px;
    font-weight: 100;
    font-size: 2em;
    margin-top: 82px;
    /* border: thin solid lightgray;
    background-color: whitesmoke; */
    
}

.ActivityHistory .notes {
    list-style: none;
    padding: 0;
    margin-bottom: 150px;
    font-weight: 100;
}

.ActivityHistory .notes .note {
    display: -webkit-flex;
    display: flex;
    text-align: left;
    font-size: 0.8em;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px 10px 10px 10px;
    border-top: 1px solid #F4F5F7;
    min-height: 80px;
    color: #212529;
}

.ActivityHistory .notes .note i {
    color: #5DC2A7;
}

.ActivityHistory .notes .note a {
    text-transform: uppercase;
}

.ActivityHistory .notes .note .text {
    max-width: 70%;
    margin-right: 20px;
    
}

.ActivityHistory .back i {
    font-size: 2.5em;
    margin-top: 52px;
    cursor: pointer;
}

.Activities ul {
    /* margin-top: 50px; */
}
.Activities .p-dialog .p-dialog-content {
    padding-bottom: 40px !important;
}

.Activities .dateSelect-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    font-weight: bold;
    height: 70px;
    padding: 10px;
    margin-top: env(safe-area-inset-top);
    position: fixed;
    width: 100vw;
    background-color: #f2f2f2;
    top: 0;
    z-index: 5;
    
}

.Activities .dateSelect-wrapper .p-button,
.Activities .p-button:enabled:active, 
.Activities .p-button:enabled:hover {
    background: none;
    color: black;
    border: none;
    
}

.Activities .dateSelect-wrapper .p-button {
    font-size: 1.4em;
}

.Activities .isToday {
    text-transform: uppercase;
}

@media (min-width: 576px) {
    .Activities .dateSelect-wrapper {
        min-width: 500px;
        width: 0;
    }
}
.AchievementDialog .footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.AchievementDialog input {
    width: 90%;
    height: 50px;
    border: none;
    border-bottom: 1px solid #dddddd;
    border-radius: 0;
}

.AchievementDialog .formError {
    color: red;
    font-size: 10px;
    text-align: left;
    margin-left: 15px;
}


.Challenge .header {
    border-radius: 50%;
    /* width: 95%; */
    padding: 50%;
    box-sizing: border-box;
    /* position: absolute; */
    /* top: 0%; */
    margin: -65% auto 0 auto;
    display: -webkit-flex;
    display: flex;
    /* left: 50%; */
    -webkit-justify-content: center;
            justify-content: center;
}

.Challenge .textWrapper {
    /* display: flex; */
    width: 100%;
    position: absolute;
    max-width: 242px;
    top: 90px;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 20px;
    /* left: 50%; */
}

.Challenge .Text, .Challenge .subText {
    color: rgb(255, 255, 255);
    width: 100%;
    /* position: absolute; */
    left: 0;
    top: 45px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    left: -50%;
}

.Challenge .subText {
    text-transform: capitalize;
    top: 80px;
    /* padding: 0 45px; */
    font-weight: 500;
    
}

/* .Challenge .header {
    color: white;
    text-transform: uppercase;
    flex-direction: row;
    padding: 0 0 10px 0;
    font-weight: bold;
    text-align: center;
} */

@media only screen and (min-width: 500px) {
    .Challenge .Text {
        
        top: 75px;
       
    }
    
    .Challenge .subText {
        top: 120px;
        
        
    }

    .Challenge .textWrapper {
        
        max-width: 300px;
    }
}
nav {
    width: 100%;
    position: fixed;
    bottom: 0;
    /* border-top: thin solid; */
    background-color: #e0e0e0;
    /* color: #010101; */
    height: 60px;
    font-size: 16px;
    z-index: 12;
    /* padding-bottom: 60px; */
    padding-top: 10px;
    font-weight: bold;
}

nav ul {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: row;
           flex-direction: row;
   -webkit-justify-content: space-around;
           justify-content: space-around;
   -webkit-align-items: center;
           align-items: center;
   padding: 0;
   list-style: none;
   
}



nav ul li a{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    color: #010101;
   
}

nav ul li a.active, nav ul li a:hover{
    color: #010101;
}

nav ul li i {
   font-size: 0.6em;
}

@media (min-width: 576px) {

    nav {
        width: 0;
        min-width: 500px;
    }
}

.Home {
    margin-bottom: 80px;
    max-width: 500px;
}

.Home .top-panel {
    min-height: 100px;
    width: 100%;
    /* padding-top: 10px; */
    border-bottom: thin solid #dddddd;
    /* border-top: thin solid #dddddd; */
}

.Home .top-panel .top-panel-left {
   border-right: thin solid #dddddd;
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: column;
           flex-direction: column;
   -webkit-align-items: center;
           align-items: center;
   -webkit-justify-content: center;
           justify-content: center;
   padding-top: 20px;
}

.Home .top-panel .top-panel-right {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    padding-top: 20px;
    
}

.Home .top-panel .top-panel-left h1, 
.Home .top-panel .top-panel-right h1 {
    background: #F2F2F2;
  border-radius: 2em;
  -moz-border-radius: 2em;
  -webkit-border-radius: 2em;
  color: #080808;
  display: inline-block;
  /* font-weight: bold; */
  line-height: 1.2em;
  margin-right: 5px;
  text-align: center;
  min-width: 2em;
  padding: 0.4em; 
}

.Home .top-panel .top-panel-right h1 {
    background: #f2f2f2;
    color: #080808;
}

.Home .main {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}

.Home .leaderboard {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin: auto;
    width: 100%;
    margin: 0;
}



.Home .avatar-thumb {
    width: 50px;
    margin-right: 10px;
    
   
}

.Home .avatar-thumb img {

    width: 50px;
    height: 50px;
    -webkit-clip-path: circle(25px at center);
            clip-path: circle(25px at center);
    object-fit: cover;
}

.Home .points {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 50px;
    font-size: 2em;
}
.Loader .overlay {
    height: 100%;
    position: fixed;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 100%;
    background: black;
    opacity: 0.4;
    z-index: 100;
    left: 0;
    top: 0;
    
}

.Loader .text {
    z-index: 12;
    opacity: 1;
    color: white;
    position: fixed;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 2em;
    width: 100%;
    height: 100%;
    left: 0;
}
.Top10 {
    width: 100%;
    margin-top: 50px;
}
.Top10 ul {
    list-style: none;
    
    
    padding: 0;
}

.Top10 li {
    border-bottom: lightgrey thin solid;
    width: 100%;
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.Top10 .me {
    background-color: rgb(248, 223, 152);
}

.Top10 h2 {
    font-weight: 200;
}
.Leaderboard {
    /* font-weight: 200; */
    
}

.Leaderboard .entry {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 5px 5px 5px 0;
    -webkit-align-items: center;
            align-items: center;
    border-bottom: #eee thin solid;
    color: #0f0f0f;
}

.Leaderboard .entry .cell {
    z-index: 1;
}
.Leaderboard .entry .cell.avatar {
    /* width: 16vw; */
    color: black;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
}
.Leaderboard .entry .cell.name {
    
}
.Leaderboard .entry .cell.points {
    /* width: 25vw; */
    font-size: 1.5em;
}

.Leaderboard .avatar {
    width: 50px;
    height: 50px;
    -webkit-clip-path: circle(25px at center);
            clip-path: circle(25px at center);
    object-fit: cover;
    left: 0;
}

.Leaderboard .avatar img {
    width: 50px;
    height: 50px;
    -webkit-clip-path: circle(25px at center);
            clip-path: circle(25px at center);
    object-fit: cover;
}

.progressbar {
    position: absolute;
    top: 5px;
    bottom: 5px;
    /* left: 15vw; */
    border-radius: 30px;
    /* background-color: #C0D95E;b5ce55 */
    background-color: #b5ce55;
    /* height: 40px; */
    height: 50px;
    
}

.isMe {
    position: absolute;
    top: 5px;
    bottom: 5px;
    /* left: 15vw; */
    border-radius: 30px;
    background-color: #FC844C;
    height: 50px;
    
}

.empty-progressbar {
    position: absolute;
    top: 5px;
    bottom: 5px;
    /* left: 15vw; */
    border-radius: 30px;
    /* background-color: #dfdfdf; */
    background-color: #e0e0e0;
    height: 50px;
    
}

.Leaderboards {
    /* margin-top: 53px; */
    /* margin-bottom: 150px; */
    /* max-height: 90vh; */
    /* background: linear-gradient(#e9a3f5, #8161da); */
    
    width: 90%;
    
    
    
    position: relative;
}

.leaderboard-text {
    padding: 15px;
    /* font-weight: 200; */
    /* color: white; */
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin: auto;
    
}

.Leaderboards .heading-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    background-color: #e0e0e0;
}

.noLeaderboards {
    color: white;
}

.board {
    /* width: 90vw; */
    -webkit-align-self: center;
            align-self: center;
    margin-left: auto;
    margin-right: auto;
    background: white;
    box-shadow: 0px -10px 40px #fff;
}

.tab {
    overflow: hidden;
    /* background-color: rgb(1, 171, 181); */
    background-color: white;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 53px;
    z-index: 3;
    margin-top: 10px;
    margin-bottom: 10px;
}

.tab2 {
    top: 93px;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    /* background-color: rgb(68, 33, 97); */
    background-color: white;
}

.tab button {
    background-color: inherit;
    color: #0f0f0f;
    font-weight: bold;
    border: thin solid #0f0f0f;
    outline: none;
    cursor: pointer;
    width: 40vw;
    height: 50px;
    margin: 5px;
    transition: 0.3s;
    border-radius: 30px;
}

.tab button:hover {
    /* background-color: rgb(237, 19, 120); */
    color: #000000
}

.tab button.active {
    /* background-color: rgb(237, 19, 120); */
    color: white;
    background-color: #000000;
    
}

.tab2 button:hover {
    color: rgb(231, 108, 37);
}

.tab2 button.active {
    background-color: rgb(231, 108, 37);
    color: white;
}

@media (min-width: 576px) {
    .Leaderboards {
        max-width: 500px;
        /* width: 0; */
    }
}

.NoTeam {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
    /* position: absolute; */
    /* width: 100%; */
    margin-left: 40px;
    margin-right: 40px;
    min-height: 400px;
}

.NoTeam .AddButton {
    position: absolute;
    bottom: 80px;
    right: 20px;
}

.NoTeam .AddJoinPanel {
    background-color: #4c3494;
    width: 100%;
    position: fixed;
    bottom: 50px;
    height: 120px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    padding: 0 50px;
    color: white;
    font-weight: bold;
    
}

.NoTeam .AddJoinPanel a.white i {
    color: #6D7173 !important;
}


.btn-floating {
    display: inline-block;
    color: #fff;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    background-color: #4c3494;
    border-radius: 50%;
    transition: background-color .3s;
    cursor: pointer;
    vertical-align: middle;
  }

  .btn-floating.btn-large {
    width: 56px;
    height: 56px;
    padding: 0;
  }
  
  .btn-floating.btn-large.halfway-fab {
    bottom: -28px;
  }
  
  .btn-floating.btn-large i {
    line-height: 56px;
  }

  .white {
    background-color: #FFFFFF !important;
  }

  .z-depth-1, nav, .card-panel, .card, .toast, .btn, .btn-large, .btn-small, .btn-floating, .dropdown-content, .collapsible, .sidenav {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }
  
  .z-depth-1-half, .btn:hover, .btn-large:hover, .btn-small:hover, .btn-floating:hover {
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
  }
.Team {
    /* display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-top: thin solid lightgrey; */
}
 .Team .flag {
     width: 30px;
     margin-right: 5px;
 }
.Team .teamPanel {
    width: 100%;
}

.Team .teamPanel .p-panel-titlebar{
    background-color: white;
    border: none;
    color: lightgray;
    padding: 20px;
    
    
}

.Team .teamPanel .p-panel-titlebar .p-panel-title {
    font-weight: 200;
    color: black;
}

.Team .teamPanel .p-panel-titlebar .p-panel-titlebar-icon{
    
    color: #01abb5;
    
    
}

.Team .teamPanel .p-panel-titlebar .p-panel-titlebar-icon:hover, 
.Team .teamPanel .p-panel-titlebar .p-panel-titlebar-icon:active,
.Team .teamPanel .p-panel-titlebar .p-panel-titlebar-icon:focus {
    color: inherit !important;
    border-color: none;
    box-shadow: none;
}

.Team .member {
    list-style: none;
    padding: 0;
}

.Team ul {
    padding: 0;
    text-align: left;
}

.MyTeamDetails {
    margin-top: 52px;
}

.MyTeamDetails .fileUpload {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;


}
.MyTeamDetails .title {
    font-weight: bold;
    color: #6D7173 !important;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 20px;
    background-color: #F9F9F9;
}

.MyTeamDetails .team-memberlist {
    text-decoration: none;
    list-style: none;
    text-align: left;
    padding: 0;
    
}

.MyTeamDetails .team-memberlist li {
    /* margin-left: 30px; */
}

.MyTeamDetails .title button {
    border: none !important;
    background-color: transparent !important;
    color: #6D7173 !important;
}

.MyTeamDetails .avatar-thumb i {

    width:140px;
    height: 140px;
    -webkit-clip-path: circle(70px at center);
            clip-path: circle(70px at center);
    font-size: 4.4em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: #f9f9f9;
}

.MyTeamDetails .avatar-thumb {
    width: 80px;
    margin-right: 10px;
    cursor: pointer;
   
}

.MyTeamDetails .avatar-thumb img {

    width: 140px;
    height: 140px;
    -webkit-clip-path: circle(70px at center);
            clip-path: circle(70px at center);
    object-fit: cover;
}


.TeamMember .avatar-thumb i {

    width: 80px;
    height: 80px;
    -webkit-clip-path: circle(40px at center);
            clip-path: circle(40px at center);
    font-size: 4.4em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: #f9f9f9;
}

.TeamMember .avatar-thumb {
    width: 80px;
    margin-right: 10px;
    
   
}

.TeamMember .avatar-thumb img {

    width: 80px;
    height: 80px;
    -webkit-clip-path: circle(40px at center);
            clip-path: circle(40px at center);
    object-fit: cover;
}


/* .TeamMember i {
    margin-right: 10px;
    border: 1px solid;
    background-color: #F9F9F9;
    border-radius: 20px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: #D7D7D7;
    
} */
.TeamMember {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    color: #808080;
    padding: 10px 20px;
    border-bottom: 1px solid;
    border-color: #D7D7D7;
    width: 100%;
    
}

.TeamMember:hover {
    background-color: #F2F9F7;
}

.TeamMember .points {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 50px;
    font-size: 2em;
}

.TeamMember .name {
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
}
.CreateTeamFormDialog .footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.CreateTeamFormDialog input {
    width: 90%;
    height: 50px;
    border: none;
    border-bottom: 1px solid #dddddd;
    border-radius: 0;
}
.TeamsList.hide {
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
}

.TeamsList.show {
    -webkit-transform: translateY(none);
            transform: translateY(none);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
}

.TeamsList {
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    overflow: scroll;
    z-index: 999;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
    padding-bottom: 60px;
    
}
.TeamsList .list{
    
    
    position: relative;
    margin-bottom: 150px;
    margin-top: 50px;
    /* height: 100%; */
}

.TeamsList .title {
    font-weight: bold;
    color: #6D7173;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 10px;
    background-color: #F9F9F9;
    position: fixed;
    width: 100%;
    z-index: 3;
    
}



.TeamsList .title button {
    border: none !important;
    background-color: transparent !important;
}
.Profile {
    display: -webkit-flex;
    display: flex;

    color: #000;
    padding: 10px 0;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin-top: -42px;
    margin-bottom: 80px;
    

}

.Profile form {
    width: 98%;
    
}

.Profile .fullname {
    font-size: 1.4em;
    font-weight: bold;
    margin-bottom: 20px;
}


.Profile .avatar-thumb i {

    width: 120px;
    height: 120px;
    -webkit-clip-path: circle(60px at center);
            clip-path: circle(60px at center);
    font-size: 4.4em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: #4c3494;
    color: #fff;
    
}

.Profile .avatar-thumb {
    width: 150px;
    color: '#fff'
    
   
}

.Profile .avatar-thumb img {

    width: 150px;
    height: 150px;
    -webkit-clip-path: circle(60px at center);
            clip-path: circle(60px at center);
    object-fit: cover;
}

.Profile .p-button {
    border-radius: 30px;
}

.Profile input {
    text-align: center;
}

.Profile .p-dropdown-label {
    /* height: 100%; */
    margin: auto;
    margin-top: 13px;
    padding: 0;
}



.ChangePassword.hide {
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
}

.ChangePassword.show {
    -webkit-transform: translateY(none);
            transform: translateY(none);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
}

.ChangePassword {
    height: 100vh;
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    overflow: scroll;
    z-index: 999;

}

.ChangePassword input {
    width: 90%;
    height: 50px;
    border: none;
    border-bottom: 1px solid #dddddd;
    border-radius: 0;
}

.ChangePassword .title {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-justify-content: right;
            justify-content: right;
    -webkit-align-items: right;
            align-items: right;
    position: fixed;
    
}

.ChangePassword .passwordWrapper {
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 20px;
}

.ChangePassword .passwordWrapper p.recoverText {
    font-size: 1.5em;
    font-weight: 100;
}

.ChangePassword .passwordWrapper .buttons {
    margin-top: 10px;
}

.Share .message {
    font-size: 1.2em;
    color: red;
    text-transform: uppercase;
}

.Share .buttonWrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
}
.Layout {
    margin-top: 100px;
    max-width: 500px;

}

@media (min-width: 576px) {
    .Layout {
        min-width: 500px;
        /* text-align: center; */
    }

}
.Setting {
    /* margin-top: 52px; */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: thin solid #eee;
    padding: 20px;
}

.Setting .label {
    
}
.Preferences {
    margin-top: 52px;
}
.Placeholder {
    /* height: 100vh;
    max-width: 800px; */
    /* padding: 20px; */
    margin: auto;
    font-family: 'Open Sans', sans-serif;
    /* margin-bottom: 80px; */
}

.Placeholder .section {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    /* justify-content: center; */
    /* margin: 30px 0px; */
    padding-top: 20px;
    font-family: 'Poppins', sans-serif;
    
    
}

.Placeholder .section.text {
    padding: 20px;
}

.Placeholder .section .bold {
    font-weight: bold;
}

.Placeholder .section.text ul {
    margin-bottom: -65px;

}

.Placeholder .section.main .days {
    color: darkorange;
    font-weight: bolder;
}

.Placeholder .section.header {
    width: 100%;
    height: 20%;
    border-bottom-left-radius: 50% 20%;
    border-bottom-right-radius: 50% 20%;
    padding: 0;

}

.Placeholder .section.header img {
    width: 100%;
}

.Placeholder .section.logo img {
    /* padding: 30px; */
    /* background-color: yellow; */
    width: 100%;
    
}



.Placeholder .footer {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    position: absolute;
    bottom: 0;
    width: 100%;
    /* padding-bottom: 20px; */
    
}
.Placeholder .footer .logo img {
    width: 200px;
    margin: 20px;
    /* background-color: red; */
}

.Placeholder .section.text {
    /* background-color: blue; */
    text-align: left;
    overflow: scroll;
    
}

.Placeholder h1, .Placeholder .intro-subtitle, .Placeholder .date-title {
    color: #4141b1;
    text-align: left;
}

.Placeholder h1 {
    text-transform: uppercase;
    font-family: 'Oswald', sans-serif;
}

.Placeholder .intro-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.Placeholder .intro-text {
    text-align: left;
    /* padding: 20px; */
}

.Placeholder .intro-icon img {
    width: 80%;
}

.Placeholder .intro-image {
    margin: 0 20px 0 0;
}
.Placeholder .intro-image img {
   width: 100px;
    
}

.Placeholder .intro-icons {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
}

.Placeholder .intro-subtitle {
    font-weight: bold;
}

.Placeholder .dates-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    
}

.Placeholder .date-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    
}



.Placeholder .date-title {
    margin-right: 20px;
}

.pfooter {
    background-color: rgb(112, 223, 213);
    position: fixed;
    bottom: 0;
    width: 100%;
    
    color: white;
    height: 80px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

@media (max-width: 500px) {
    .Placeholder h1 {
        font-size: 22px;
    }
}
.Login {
    
    /* margin-top: 80px; */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: fixed;
    width: 100%;
    height: 100%;
    
}

.Login form {
    /* height: 50%; */
}

.Login input {
    width: 90%;
    height: 50px;
    border: none;
    border-bottom: 1px solid #dddddd;
    border-radius: 0;
}

.Login input[type=password] {
    font-family: inherit !important;
}

.Login .p-inputtext:enabled:focus {
    border-bottom-color: #4c3494 !important;
}

.Login .p-button {
    width: 90%;
    height: 50px;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 30px;
    
    
}

.Login .logo-wrapper {

    /* width: 100%;
    height: 60%; */
    /* background-color: red; */
    
}

.Login .form-wrapper {
    height: 50%;
}

.Login .alt-actions {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    margin-top: 10px;
}

.Login .iforgot {
    text-transform: uppercase;
    cursor: pointer;
}

.Login .register {
    text-transform: uppercase;
    cursor: pointer;
}
/* .Login .logo-wrapper .animain {
    position: relative;
}
.Login .logo-wrapper .animain .screen {
    position: relative;
    height: 100%;
} */
.Forgot.hide {
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
}

.Forgot.show {
    -webkit-transform: translateY(none);
            transform: translateY(none);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
}

.Forgot {
    height: 100vh;
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    overflow: scroll;
    z-index: 999;

}

.Forgot input {
    width: 90%;
    height: 50px;
    border: none;
    border-bottom: 1px solid #dddddd;
    border-radius: 0;
}

.Forgot .title {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-justify-content: right;
            justify-content: right;
    -webkit-align-items: right;
            align-items: right;
    position: fixed;
    
}

.Forgot .emailWrapper {
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 20px;
}

.Forgot .emailWrapper p.recoverText {
    font-size: 1.5em;
    font-weight: 100;
    
}

.Forgot .emailWrapper .buttons {
    margin-top: 10px;
}

.Forgot .shake {
    -webkit-animation: shake 0.8s;
            animation: shake 0.8s;
}

@-webkit-keyframes shake {
    0% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
    10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); transform: translate(-1px, -2px) rotate(-1deg); }
    20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); transform: translate(-3px, 0px) rotate(1deg); }
    30% { -webkit-transform: translate(3px, 2px) rotate(0deg); transform: translate(3px, 2px) rotate(0deg); }
    40% { -webkit-transform: translate(1px, -1px) rotate(1deg); transform: translate(1px, -1px) rotate(1deg); }
    50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); transform: translate(-1px, 2px) rotate(-1deg); }
    60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); transform: translate(-3px, 1px) rotate(0deg); }
    70% { -webkit-transform: translate(3px, 1px) rotate(-1deg); transform: translate(3px, 1px) rotate(-1deg); }
    80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); transform: translate(-1px, -1px) rotate(1deg); }
    90% { -webkit-transform: translate(1px, 2px) rotate(0deg); transform: translate(1px, 2px) rotate(0deg); }
    100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); transform: translate(1px, -2px) rotate(-1deg); }
  }

@keyframes shake {
    0% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
    10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); transform: translate(-1px, -2px) rotate(-1deg); }
    20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); transform: translate(-3px, 0px) rotate(1deg); }
    30% { -webkit-transform: translate(3px, 2px) rotate(0deg); transform: translate(3px, 2px) rotate(0deg); }
    40% { -webkit-transform: translate(1px, -1px) rotate(1deg); transform: translate(1px, -1px) rotate(1deg); }
    50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); transform: translate(-1px, 2px) rotate(-1deg); }
    60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); transform: translate(-3px, 1px) rotate(0deg); }
    70% { -webkit-transform: translate(3px, 1px) rotate(-1deg); transform: translate(3px, 1px) rotate(-1deg); }
    80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); transform: translate(-1px, -1px) rotate(1deg); }
    90% { -webkit-transform: translate(1px, 2px) rotate(0deg); transform: translate(1px, 2px) rotate(0deg); }
    100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); transform: translate(1px, -2px) rotate(-1deg); }
  }
