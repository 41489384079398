
.Activities ul {
    /* margin-top: 50px; */
}
.Activities .p-dialog .p-dialog-content {
    padding-bottom: 40px !important;
}

.Activities .dateSelect-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    height: 70px;
    padding: 10px;
    margin-top: env(safe-area-inset-top);
    position: fixed;
    width: 100vw;
    background-color: #f2f2f2;
    top: 0;
    z-index: 5;
    
}

.Activities .dateSelect-wrapper .p-button,
.Activities .p-button:enabled:active, 
.Activities .p-button:enabled:hover {
    background: none;
    color: black;
    border: none;
    
}

.Activities .dateSelect-wrapper .p-button {
    font-size: 1.4em;
}

.Activities .isToday {
    text-transform: uppercase;
}

@media (min-width: 576px) {
    .Activities .dateSelect-wrapper {
        min-width: 500px;
        width: 0;
    }
}